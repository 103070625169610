/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "title_colors_default": "",
  "title_size_default": "text-headline-2xs",
  "title_variant_default": "",
  "list_colors_default": "scrollbar-thumb-gray-200 scrollbar-track-white",
  "list_size_default": "gap-4 scrollbar-w-4",
  "list_variant_default": "overflow-y-auto flex flex-col scrollbar scrollbar-thumb-rounded-full",
  "list_line_colors_default": "",
  "list_line_size_default": "gap-2",
  "list_line_variant_default": "flex flex-row",
  "list_line_text_colors_default": "",
  "list_line_text_size_default": "text-input-sm",
  "list_line_text_variant_default": "",
  "list_line_time_colors_default": "text-gray-700",
  "list_line_time_size_default": "text-input-sm",
  "list_line_time_variant_default": "",
  "colors_default": "border-gray-400",
  "size_default": "gap-6 max-h-70 p-3",
  "variant_default": "border flex-col hidden data-[open]:flex lg:col-start-2"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "VideoTranscript");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;