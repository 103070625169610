/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneJwVideoVideo as ResolvedStandaloneJwVideoVideo } from 'base/components/standalone/JwVideo/Video';

export const JwVideoVideo = ResolvedStandaloneJwVideoVideo;

export type JwVideoVideoProps = PropsFromComponent<typeof JwVideoVideo>;

export const JwVideoVideoWith = (extras: DynamicStandaloneExtras): typeof JwVideoVideo => {
    return function JwVideoVideo(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'JwVideoVideo');
        return <ResolvedStandaloneJwVideoVideo {...mergeProps({ options: { theme } }, props)} />;
    }
}