import { useClassName } from '@/styles/VideoTranscript';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div' });
const Title = GenericComponent({ as: 'h6', styles: { key: 'title' } });
const List = GenericComponent({ as: 'ul', styles: { key: 'list' } });
const ListLine = GenericComponent({ as: 'li', styles: { key: 'list_line' } });
const ListLineText = GenericComponent({ as: 'span', styles: { key: 'list_line_text' } });
const ListLineTime = GenericComponent({ as: 'span', styles: { key: 'list_line_time' } });

export const VideoTranscript = Object.assign(Base, {
  Title,
  List: Object.assign(List, {
    Line: Object.assign(ListLine, {
      Text: ListLineText,
      Time: ListLineTime,
    }),
  }),
});
