import { useClassName } from '@/styles/VideoSlider';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { VideoSliderHeaderArrowComponent } from './VideoSlider.Header.Arrow';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div' });
const Header = GenericComponent({ as: 'div', styles: { key: 'header' } });
const HeaderTitle = GenericComponent({ as: 'h3', styles: { key: 'header_title' } });
const HeaderDescription = GenericComponent({ as: 'p', styles: { key: 'header_description' } });

export const VideoSlider = Object.assign(Base, {
  Header: Object.assign(Header, {
    Title: HeaderTitle,
    Description: HeaderDescription,
    Arrow: VideoSliderHeaderArrowComponent,
  }),
});
