import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useJWPlayer, VIDEO_PLAYED_TYPE } from './JWPlayerContext';
import { VideoArticle } from './VideoArticle';
import { VideoArticleExitLinkProps } from './VideoArticle.Exit.Link';
import { VideoArticleExitMarketingTextProps } from './VideoArticle.Exit.MarketingText';
import { findB2BMediaCandidate } from './b2b/getB2BCandidate';
import { getUserInformation, saveUserInformation } from './b2b/userInformation';
import { Time } from './constants/playEvents';
import { usePlayerEvent } from './hooks/usePlayerEvent';
import { useReplaceNextVideo } from './hooks/useReplaceNextVideo';
import { B2BVideoInfo } from './types/video';

export interface VideoArticleExitProps extends ComponentProps<'div'> {
  b2bVideo?: B2BVideoInfo;
  mediaId?: string;
  setShowNextVideoPopup?: (show: boolean) => void;
  linkProps?: Omit<VideoArticleExitLinkProps, 'commercialLink'>;
  labelProps?: VideoArticleExitMarketingTextProps;
  onComplete?: (player: any) => void;
}

export const VideoArticleExitComponent: Component<VideoArticleExitProps> = ({
  b2bVideo,
  setShowNextVideoPopup,
  linkProps,
  labelProps,
  onComplete: onCompleteProp,
  ...props
}) => {
  const { linkText = null, markingText = null, link = null, videoCampaigns = [] } = b2bVideo ?? {};

  const className = useClassName('exit', props);
  const linkClassName = useClassName('exit_link', linkProps ?? {});
  const labelClassName = useClassName('exit_label', labelProps ?? props);

  const [commercialLinkText, setCommercialLinkText] = useState<string | null>(linkText);
  const [commercialLink, setCommercialLink] = useState<string | null>(link);
  const [commercialMarkingText, setCommercialMarkingText] = useState<string | null>(markingText);

  const isB2BVideoInserted = useRef(false);
  const [b2bCampaignId, setB2BCampaignId] = useState<string | null>(null);
  const [hasUserVisitSaved, setHasUserVisitSaved] = useState<boolean>(false);
  const [b2bMediaId, setB2BMediaId] = useState<string | null>(null);
  //Read from jw player context.
  const { player, currentMedia } = useJWPlayer();

  const setExitLinkValues = useCallback(() => {
    setCommercialLinkText(currentMedia?.video_comm_link_text || null);
    setCommercialLink(currentMedia?.video_comm_link || null);
    setCommercialMarkingText(currentMedia?.video_comm_ad_text || null);
  }, [currentMedia]);

  useReplaceNextVideo({
    mediaId: b2bMediaId,
    type: VIDEO_PLAYED_TYPE.NATIVE,
    callback: () => {
      isB2BVideoInserted.current = true;
      setB2BMediaId(null);
    },
  });

  useEffect(() => {
    if (!currentMedia) return;
    setExitLinkValues();
  }, [currentMedia, setExitLinkValues]);

  const getB2BMedia = useCallback(() => {
    if (!videoCampaigns.length) return;

    const userInfo = getUserInformation();
    const { campaignId, mediaId } = findB2BMediaCandidate(videoCampaigns, userInfo) ?? {};
    if (campaignId && mediaId) {
      setB2BCampaignId(campaignId);
      return mediaId;
    }
  }, [videoCampaigns]);

  const fetchB2BMediaInfo = async () => {
    if (!linkText && !isB2BVideoInserted.current) {
      const b2bMediaId = getB2BMedia() || null;
      if (b2bMediaId) {
        setB2BMediaId(b2bMediaId);
        setShowNextVideoPopup && setShowNextVideoPopup(false);
      } else {
        setShowNextVideoPopup && setShowNextVideoPopup(true);
      }
    }
  };

  const onTime = useCallback(
    async ({ position, duration }: { position: number; duration: number }) => {
      const videoWatched = (position / duration) * 100;
      if (!b2bMediaId && videoWatched >= 90) {
        fetchB2BMediaInfo();
      }
      if (!isB2BVideoInserted.current || !b2bCampaignId) return;
      const B2B_MIN_VIEW_DURATION = 3;

      if (!hasUserVisitSaved && b2bCampaignId) {
        setHasUserVisitSaved(true);
        await saveUserInformation(b2bCampaignId, getUserInformation());
      }
      //Record goal reached
      const hasSeenB2BVideoForExpectedDuration = position >= B2B_MIN_VIEW_DURATION;
      if (hasSeenB2BVideoForExpectedDuration) {
        setB2BCampaignId(null);
      }
    },
    [b2bCampaignId, hasUserVisitSaved],
  );

  usePlayerEvent(player, Time, onTime);
  return (
    <div {...mergeProps({ className }, props)}>
      {/* For commercial link Button */}
      {commercialLinkText && commercialLink && (
        <VideoArticle.Exit.Link
          commercialLink={commercialLink}
          {...mergeProps({ className: linkClassName }, linkProps)}
        >
          {commercialLinkText}
        </VideoArticle.Exit.Link>
      )}

      {/* For commercial ad text */}
      {commercialMarkingText && (
        <VideoArticle.Exit.MarketingText {...mergeProps({ className: labelClassName }, labelProps)}>
          {commercialMarkingText}
        </VideoArticle.Exit.MarketingText>
      )}
    </div>
  );
};
