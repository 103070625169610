import { ClassNameProvider } from '@/styles/VideoTranscript';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Transcription } from 'lib/data/video/types';
import { ReactNode } from 'react';
import { VideoTranscript } from './VideoTranscript';

export interface StandaloneVideoTranscriptProps extends StandaloneComponentProps<typeof VideoTranscript> {
  title?: ReactNode;
  transcription?: Transcription[];
}

export const StandaloneVideoTranscript: StandaloneComponent<StandaloneVideoTranscriptProps> = ({
  title,
  transcription,
  options,
  ...props
}) => {
  const { $title, $list: listProps, ...$base } = options ?? {};
  const { $line: listLineProps, ...$list } = listProps ?? {};
  const { $text: $listLineText, $time: $listLineTime, ...$listLine } = listLineProps ?? {};

  if (!transcription?.length) {
    return null;
  }

  return (
    <ClassNameProvider value={$base}>
      <VideoTranscript {...$base} {...props}>
        {title && <VideoTranscript.Title {...$title}>{title}</VideoTranscript.Title>}

        <VideoTranscript.List {...$list}>
          {transcription.map((transcript, index) => (
            <VideoTranscript.List.Line key={index} {...$listLine}>
              <VideoTranscript.List.Line.Time {...$listLineTime}>{transcript.start}</VideoTranscript.List.Line.Time>
              <VideoTranscript.List.Line.Text {...$listLineText}>{transcript.text}</VideoTranscript.List.Line.Text>
            </VideoTranscript.List.Line>
          ))}
        </VideoTranscript.List>
      </VideoTranscript>
    </ClassNameProvider>
  );
};

export default StandaloneVideoTranscript;
