/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneVideoTranscript as ResolvedStandaloneVideoTranscript } from 'base/components/standalone/VideoTranscript';

export const VideoTranscript = ResolvedStandaloneVideoTranscript;

export type VideoTranscriptProps = PropsFromComponent<typeof VideoTranscript>;

export const VideoTranscriptWith = (extras: DynamicStandaloneExtras): typeof VideoTranscript => {
    return function VideoTranscript(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'VideoTranscript');
        return <ResolvedStandaloneVideoTranscript {...mergeProps({ options: { theme } }, props)} />;
    }
}