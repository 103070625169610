import { useClassName } from '@/styles/JwVideoVideo';
import { ComponentProps, ComponentWithRef } from '@/types/component';
import isClient from '@/utils/isClient';
import { mergeProps } from '@/utils/merge';
import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

export interface VideoActionsModalProps extends ComponentProps<'dialog'> {
  iconSize?: number;
}

const shareChannels = [
  {
    Icon: FacebookIcon,
    ShareButton: FacebookShareButton,
  },
  {
    Icon: TwitterIcon,
    ShareButton: TwitterShareButton,
  },
  {
    Icon: EmailIcon,
    ShareButton: EmailShareButton,
  },
];

export const VideoActionsModalComponent: ComponentWithRef<HTMLDialogElement, VideoActionsModalProps> = React.forwardRef(
  function VideoActionsModalComponent({ children, iconSize, ...props }, ref) {
    const shareUrl = isClient() ? window.location.href : '';
    const className = useClassName('actions_modal', props);

    return (
      <dialog ref={ref} {...mergeProps({ className }, props)}>
        {shareChannels.map(({ Icon, ShareButton }, index) => (
          <ShareButton key={index} url={shareUrl}>
            <Icon size={iconSize ?? 42} round />
          </ShareButton>
        ))}
      </dialog>
    );
  }
);
