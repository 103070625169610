import { createContext, useCallback, useContext, useState } from 'react';
import { HORIZONTAL_ASPECT_RATIO } from './constants';
import { AdEnd, AdStart, Mute, Pause, Play, PlaylistItem, RelatedPluginReady, Stop } from './constants/playEvents';
import useOrientationTracking from './hooks/useOrientationTracking';
import { usePlayerEvent } from './hooks/usePlayerEvent';
import { PlaylistItem as PlaylistItemContent } from './types/video';
import getAspectRatio from './utils/getAspectRatio';

export const VIDEO_PLAYED_TYPE = {
  NATIVE: 'native',
  RECOMMENDED: 'recommended',
  VERTICAL: 'vertical',
  EDITORIAL: 'editorial',
} as const;
interface JWPlayerContextType {
  player: any;
  isMute: boolean;
  isPlaying: boolean;
  currentMedia: PlaylistItemContent | null;
  currentMediaAspectRatio: string;
  onPlayerSet: (p: any) => void;
  playedTypes: string[];
  updatePlayedTypes: (type: string) => void;
  videoPlacementPriority: string[];
  setVideoPlacementPriority: (priorityList: string[]) => void;
}

const JWPlayerContext = createContext<JWPlayerContextType>({} as JWPlayerContextType);

export function useJWPlayer(): JWPlayerContextType {
  const context = useContext(JWPlayerContext);
  if (context === undefined) {
    throw new Error(`usePlayer must be used within a JWPlayerProvider`);
  }
  return context;
}

export function JWPlayerProvider(props: { children?: React.ReactNode }) {
  const { children } = props;
  const [player, setPlayer] = useState<any>(null);
  const [currentMedia, setCurrentMedia] = useState<PlaylistItemContent | null>(null);
  const [isMute, setIsMute] = useState<boolean>(false);

  const [relatedPlugin, setRelatedPlugin] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playedTypes, setPlayedTypes] = useState<string[]>([VIDEO_PLAYED_TYPE.EDITORIAL]);
  const [videoPlacementPriority, setVideoPlacementPriority] = useState<string[]>([]);
  const [currentMediaAspectRatio, setCurrentMediaAspectRatio] = useState<string>(
    getAspectRatio(currentMedia?.allSources),
  );

  usePlayerEvent(player, RelatedPluginReady, () => {
    setRelatedPlugin(player.getPlugin('related'));
  });

  useOrientationTracking({ player });

  // @ts-expect-error
  usePlayerEvent(relatedPlugin, 'open', () => {
    window.cts.send?.event?.('action', 'click', 'related-video|open');
  });

  usePlayerEvent(relatedPlugin, Play, ({ item }: any) => {
    window.cts.send?.event?.('action', 'click', `related-videos|play|${item.mediaid}`);
  });
  // @ts-expect-error
  usePlayerEvent(relatedPlugin, 'close', () => {
    window.cts.send?.event?.('action', 'click', 'related-videos|close');
  });

  const onPlayerSet = useCallback((p: any) => {
    setPlayer(p);
    setIsMute(p.getMute());
  }, []);

  const onMuteEvent = useCallback(({ mute }: { mute: boolean }) => {
    setIsMute(mute);
  }, []);

  const onPlayerPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onPlayerPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onPlayerStop = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const setVideoAspectRatio = useCallback(
    (media = currentMedia) => {
      setCurrentMediaAspectRatio(getAspectRatio(media?.allSources));
    },
    [currentMedia],
  );

  const onAdStart = useCallback(() => {
    setCurrentMediaAspectRatio(HORIZONTAL_ASPECT_RATIO);
  }, []);

  const onAdEnd = useCallback(() => {
    if (currentMedia) {
      setVideoAspectRatio();
    } else {
      // fix for the case when there is not currentMedia set after ad finish
      // in some cases, order of firing events is not consistent - safari
      const [playlist] = player.getPlaylist() ?? [];

      setVideoAspectRatio(playlist);
      setCurrentMedia(playlist);
    }
  }, [currentMedia, player, setVideoAspectRatio]);

  const onPlaylistItem = useCallback(
    ({ item }: { item: PlaylistItemContent }) => {
      setVideoAspectRatio(item);
      setCurrentMedia(item);
    },
    [setVideoAspectRatio],
  );

  usePlayerEvent(player, Mute, onMuteEvent);
  usePlayerEvent(player, Play, onPlayerPlay);
  usePlayerEvent(player, Pause, onPlayerPause);
  usePlayerEvent(player, Stop, onPlayerStop);
  usePlayerEvent(player, PlaylistItem, onPlaylistItem);
  usePlayerEvent(player, AdStart, onAdStart);
  usePlayerEvent(player, AdEnd, onAdEnd);

  const updatePlayedTypes = useCallback(
    (type: string) => {
      setPlayedTypes([...playedTypes, type]);
    },
    [playedTypes],
  );

  const contextValue: JWPlayerContextType = {
    player,
    isMute,
    isPlaying,
    currentMedia,
    currentMediaAspectRatio,
    onPlayerSet,
    playedTypes,
    updatePlayedTypes,
    videoPlacementPriority,
    setVideoPlacementPriority,
  };

  return <JWPlayerContext.Provider value={contextValue}>{children}</JWPlayerContext.Provider>;
}
