/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneVideoSlider as ResolvedStandaloneVideoSlider } from 'sites/svenskdam/components/standalone/VideoSlider';

export const VideoSlider = ResolvedStandaloneVideoSlider;

export type VideoSliderProps = PropsFromComponent<typeof VideoSlider>;

export const VideoSliderWith = (extras: DynamicStandaloneExtras): typeof VideoSlider => {
    return function VideoSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'VideoSlider');
        return <ResolvedStandaloneVideoSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}